.login-form {
  max-width: 300px;
  margin: auto;
}

.login-form-forgot {
  float: right;
}

.login-form-button {
  width: 100%;
}

.login-container {
  align-items: center;
  padding-top: 10%;
}