.register-form {
  max-width: 300px;
  margin: auto;
}

.register-form-forgot {
  float: right;
}

.register-form-button {
  width: 100%;
}

.register-container {
  align-items: center;
  padding-top: 10%;
}