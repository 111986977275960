table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

th.myTh {
    width: 30%;
}

td.myTd,
th.myTh {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}