.book {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #FAFAFA;
    font: 11pt "Tahoma";
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.page {
    width: 210mm;
    min-height: 297mm;
    padding: 10mm;
    margin: 10mm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.subpage {
    padding: 1cm;
    border: 5px red solid;
    height: 257mm;
    outline: 2cm #FFEAEA solid;
}

@page {
    size: A4;
    margin: 0;
}

@media print {

    html,
    body {
        width: 210mm;
        height: 297mm;
    }

    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }
}

.row {
    display: flex;
}

.justify {
    justify-content: space-between;
}

.shape {
    border-bottom: 30px solid #1e8075;
    border-right: 15px solid transparent;
    height: 0;
    width: 100%;
}

.shapeshort {
    border-bottom: 30px solid #1e8075;
    border-right: 15px solid transparent;
    height: 0;
    width: 300px;
}

.shapeshort2 {
    width: 20px;
    height: 30px;
    transform: skew(27deg);
    background: #1e8075;
}

.shapeshort3 {
    width: 500px;
    height: 15px;
    transform: skew(27deg);
    background: #b7da2c;
    margin-top: 15px;
    margin-left: 10px;
}

.logo img {
    width: 250px;

}

.identitas .judul {
    font-weight: 700;
    font-size: 24px;
    text-align: right;
}

.identitas .alamat {
    font-size: 12px;
    text-align: right;
}

.form-judul {
    padding-top: 30px;
    font-size: 18px;
    font-weight: 700;
    margin: auto;
}

.nomor {
    margin: auto;
    padding-bottom: 30px;
}

.shape .paket,
.datacalon {
    padding-top: 5px;
    padding-left: 10px;
    color: white;
    font-weight: 700;
}

.paketumrah,
.datacalonumroh {
    margin: 10px;
}