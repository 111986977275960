table{
    margin-bottom: 10px;
}

.tr-detail {
    height: 30px;
}

.th-detail {
    width: 200px;
}

.nominal{
    display: inline-block;
    width: 100px;
}