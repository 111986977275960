/* Di dalam file CSS Anda */
@font-face {
    font-family: 'Raleway Dots';
    src: url('../../../../fonts/Raleway_Dots/RalewayDots-Regular.ttf') format('truetype'), ;
    font-weight: normal;
    font-style: normal;
}

.my-custom-text {
    font-family: 'Raleway Dots', sans-serif;
    /* Gaya CSS lainnya */
}
